<template>
  <div class="main">
    <div class="categories content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item href="">
          <router-link to="/dashboard"><span>Dashboard</span></router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link to="/category"><span>Category</span></router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          Edit
        </a-breadcrumb-item>
      </a-breadcrumb>
      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="back">
            <a-button type="primary" ghost>
              <router-link to="/category"><ArrowLeftOutlined /> Back</router-link>
            </a-button>
          </div>
        </a-col>
      </a-row>
      <a-row class="body">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="category">
          <div class="title">Edit <span v-if="subCatFlag">Sub Category</span><span v-else>Category</span></div>
          <div class="hr-line"><hr /></div>
          <div class="body">
            <AddEditCategory/>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script lang="ts">
import AddEditCategory from '@/components/agency/course_category/AddEditCategory.vue'
import { defineComponent, onMounted, ref } from 'vue'
import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    ArrowLeftOutlined,
    HomeOutlined,
    AddEditCategory
  },
  setup () {
    const route = useRoute()
    const subCatFlag = ref<boolean>(false)
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    onMounted(() => {
      if (route.params.sub) {
        subCatFlag.value = true
      }
    })
    return {
      subCatFlag,
      notify
    }
  }
})
</script>

<style lang="scss">
.categories {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .back {
      float: right;
      .ant-btn {
        border-color: #7EAF1A;
        border-radius: 25px;
        font-family: "TT Norms Pro Medium";
        color: #7EAF1A;
        a {
          color: #7EAF1A;
        }
      }
    }
  }
}
</style>
